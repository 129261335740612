import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [

  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '',
    },
  },
  {
    name: 'caseGroup',
    path: '/case/group',
    component: () => import('./view/case/group'),
    meta: {
      title: '案例列表',
    },
  }, 
  {
    name: 'jobItem',
    path: '/job/item',
    component: () => import('./view/job/item'),
    meta: {
      title: '招聘岗位详情',
    },
  }, 
  {
    name: 'companyItem',
    path: '/company/item',
    component: () => import('./view/company/item'),
    meta: {
      title: '企业详情',
    }, 
  },

  {
    name: 'aboutUs',
    path: '/about/us',
    component: () => import('./view/about/us'),
    meta: {
      title: '关于我们',
    },
  }, 
  {
    name: 'aboutContact',
    path: '/about/contact',
    component: () => import('./view/about/contact'),
    meta: {
      title: '联系我们',
    }, 
  },

];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if ( title ) {

    //document.title = title + ' | 广西直聘人力资源集团';
    document.title = title
    
  } else {
    //document.title = '广西直聘人力资源集团';
    document.title = '';
  }
  
  next();
});

export { router };
